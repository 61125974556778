export default [
    {
        type: 'selection',
        width: 38,
        align: 'center'
    },
    {
        title: 'Date Applied',
        key: 'DateCreated',
        minWidth: 80,
        sortable: true,
    },
    {
        title: 'Date',
        key: 'date',
        minWidth: 80,
        sortable: true,
    },
    { 
        title: 'Time',
        key: 'Time',
        minWidth: 100,
    },
    {
        title: 'Status',
        key: 'status',
        minWidth: 100,
    },
    {
        title: 'Notes',
        key: 'notes',
        minWidth: 100,
    },
]
